import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAn_dMIBfsXzJBUWCvQZxOKT33AcWxD0FQ",
    authDomain: "autoembed-cce71.firebaseapp.com",
    projectId: "autoembed-cce71",
    storageBucket: "autoembed-cce71.appspot.com",
    messagingSenderId: "504589826271",
    appId: "1:504589826271:web:1b6390234adf3fe31b516a",
    measurementId: "G-WS7P1C09HT"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;