import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadingGrid from "../Loading/LoadingGrid";
import PosterGrid from "../PosterGrid/PosterGrid";

import { SignedIn, SignedOut } from "../UserState/UserState";
import Seo from "../Seo";
import "./Homepage.scss";
import Announcement from "../Ads/Announcement";

// Import Font Awesome
import "@fortawesome/fontawesome-free/css/all.min.css";

function Homepage({
  documentary,
  movies,
  network,
  thriller,
  kids,
  series,
  scifi,
  horror,
  loadingMovies,
  loadingScifi,
  loadingKids,
  loadingThriller,
  loadingHorror,
  loadingShows,
  loadingDocumentaries
}) {
  return (
    <>
      <Seo
        title="Autoembed"
        keywords="Free movies 2024, Google Drive Movies, Stream movies download, download movie collection, watch free movies in hd quality"
        descriptions="Discover a lot of latest/old movies & series collection and stream and download in HD Quality for free!"
      />
      <div id="homepage" className="container">
        <Announcement />
        <div className="home">Home</div>

        <section>
          <div className="main-blogs">
            <SignedIn>
              {() => (
                <>
                  <div className="main-blog anim" style={{ "--delay": ".1s" }}>
                <div className="main-blog__title">
                  Discover movies and TV shows and stream them all free and unlimited.
                </div>
                <div className="main-blog__author">
                  <div className="author-detail">
                    <div className="main-blog__author">
                      <div className="author-img__wrapper">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                        <img className="author-img" src="https://imgur.com/msCn4jD.png" alt="Author" />
                      </div>
                      <div className="auth-detail">
                        <div className="author-name">Yes, It's all free</div>
                        <div className="author-info">
                          HD Quality<span className="seperate"></span>CC<span className="seperate"></span>Watchlist
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-blog__time">
                  <Link className="buttongg" to="/movies/year/2024">New Movies</Link>
                </div>
              </div>

              <div className="main-blog anim1" style={{ "--delay": ".2s" }}>
                <div className="main-blog__title">
                  If you like us share our site with your friends.
                </div>
                <div className="main-blog__author1">
                  <div className="author-detail1">
                    <div className="main-blog__author1">
                      <div className="author-img__wrapper">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                        <img className="author-img" src="https://imgur.com/wWthMb6.png" alt="Author" />
                      </div>
                      <div className="auth-detail1">
                        <div className="author-name">Join our social groups</div>
                        
                        <div className="author-info1">
                          <a
                            href="https://t.me/auto_embed"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link telegram"
                          >
                            <i className="fab fa-telegram-plane icon"></i>
                            Telegram
                          </a>
                          <span className="separator"></span>
                          <a
                            href="https://discord.com/invite/BWDSXV9aX4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link discord"
                          >
                            <i className="fab fa-discord icon"></i>
                            Discord
                          </a>
                        </div>
                      

                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </>
              )}
            </SignedIn>

            <SignedOut>
              <div className="main-blog anim" style={{ "--delay": ".2s" }}>
                <div className="main-blog__title">
                  Discover movies and TV shows and stream them all free and unlimited.
                </div>
                <div className="main-blog__author">
                  <div className="author-detail">
                    <div className="main-blog__author">
                      <div className="author-img__wrapper">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                        <img className="author-img" src="https://imgur.com/msCn4jD.png" alt="Author" />
                      </div>
                      <div className="auth-detail">
                        <div className="author-name">Yes, It's all free</div>
                        <div className="author-info">
                          HD Quality<span className="seperate"></span>CC<span className="seperate"></span>Watchlist
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-blog__time">
                  <Link className="buttongg" to="/movies/year/2024">New Movies</Link>
                </div>
              </div>

              <div className="main-blog anim1" style={{ "--delay": ".1s" }}>
                <div className="main-blog__title">
                  If you like us share our site with your friends.
                </div>
                <div className="main-blog__author1">
                  <div className="author-detail1">
                    <div className="main-blog__author1">
                      <div className="author-img__wrapper">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                        <img className="author-img" src="https://imgur.com/wWthMb6.png" alt="Author" />
                      </div>
                      <div className="auth-detail1">
                        <div className="author-name">Join our social groups</div>
                        
                        <div className="author-info1">
                          <a
                            href="https://t.me/auto_embed"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link telegram"
                          >
                            <i className="fab fa-telegram-plane icon"></i>
                            Telegram
                          </a>
                          <span className="separator"></span>
                          <a
                            href="https://discord.com/invite/BWDSXV9aX4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link discord"
                          >
                            <i className="fab fa-discord icon"></i>
                            Discord
                          </a>
                        </div>
                      

                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SignedOut>
          </div>

          <h2>Now playing movies</h2>
          {loadingMovies ? <LoadingGrid grid="true" /> : <PosterGrid movies={movies} view="grid" type="movie" />}
        </section>

        <section>
          <h2>Top Thriller</h2>
          {loadingThriller ? <LoadingGrid grid="true" /> : <PosterGrid movies={thriller} view="grid" type="movie" />}
        </section>

        <section>
          <h2>Top Sci-Fi</h2>
          {loadingScifi ? <LoadingGrid grid="true" /> : <PosterGrid movies={scifi} view="grid" type="movie" />}
        </section>

        <section>
          <h2>Top Kids</h2>
          {loadingKids ? <LoadingGrid grid="true" /> : <PosterGrid movies={kids} view="grid" type="movie" />}
        </section>

        <section>
          <h2>Top Horror</h2>
          {loadingHorror ? <LoadingGrid grid="true" /> : <PosterGrid movies={horror} view="grid" type="movie" />}
        </section>

        <section>
          <div className="main-blogs">
            <div className="main-blog1">
              <div className="main-blog__title">Keep updated on what's happening!</div>
              <div className="main-blog__author tips">
                <div className="main-blog__time">
                  <a className="buttongg" href="https://discord.com/invite/BWDSXV9aX4" target="_blank" rel="noreferrer">
                    Discord
                  </a>
                </div>
                <div className="author-detail">
                  <div className="author-name">All the info is here</div>
                  <div className="author-info">
                    Announcement<span className="seperate"></span>Fix & Issues<span className="seperate"></span>And More
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2>Now airing TV shows</h2>
          {loadingShows ? <LoadingGrid grid="true" /> : <PosterGrid movies={series} view="grid" type="series" />}
        </section>

        <section>
          <h2>Top Documentaries</h2>
          {loadingDocumentaries ? <LoadingGrid grid="true" /> : <PosterGrid movies={documentary} view="grid" type="movie" />}
        </section>
      </div>
    </>
  );
}

Homepage.propTypes = {
  documentary: PropTypes.array,
  movies: PropTypes.array,
  network: PropTypes.array,
  thriller: PropTypes.array,
  kids: PropTypes.array,
  series: PropTypes.array,
  scifi: PropTypes.array,
  horror: PropTypes.array,
  loadingMovies: PropTypes.bool,
  loadingScifi: PropTypes.bool,
  loadingKids: PropTypes.bool,
  loadingThriller: PropTypes.bool,
  loadingHorror: PropTypes.bool,
  loadingShows: PropTypes.bool,
  loadingDocumentaries: PropTypes.bool,
};

export default Homepage;
