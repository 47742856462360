import React, { useEffect } from 'react';
import Toggle from './Toggle';
import useDarkMode from 'use-dark-mode';

const DarkModeToggle = () => {
  // Aqui definimos o tema escuro como padrão, alterando o parâmetro para 'true'
  const darkMode = useDarkMode(true); // Passando `true` para iniciar no modo escuro

  // useEffect para garantir que o tema escuro seja aplicado ao carregar
  useEffect(() => {
    if (darkMode.value) {
      darkMode.enable(); // Ativa o modo escuro se estiver habilitado
    } else {
      darkMode.disable(); // Desativa o modo escuro se não estiver
    }
  }, [darkMode]);

  return (
    <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
  );
};

export default DarkModeToggle;
