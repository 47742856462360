import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Questions.scss";

class Questions extends Component {
  render() {
    return (
      <div className="container discord">
        <iframe
          src="https://discord.com/widget?id=1246871601304305754&theme=dark"
          width="100%"
          height="500" // Adjust height as needed
          allowtransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          title="Discord Widget"
        ></iframe>
        <div className="join-button-container">
          <a
            href="https://discord.com/invite/BWDSXV9aX4" // Replace with your invite code
            target="_blank"
            rel="noopener noreferrer"
            className="join-button"
          >
           
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(Questions);
