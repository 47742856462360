import React, { Component } from "react";
import PropTypes from "prop-types";
import LoginHandler from "../Login/LoginHandler";
import { withRouter } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import DarkModeToggle from "../Darkmode/DarkModeToggle";
import DownloadButton from "../Download/DownloadButton";
import { SignedIn, SignedOut } from "../UserState/UserState";
import UserIcon from "../../assets/user.svg";
import { signOut } from "../../Firebase/UserUtils"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import "./Header.scss";




/**
 * The header for desktop, which includes search bar and user info
 */
function DesktopHeader({
  setSearchbarValue,
  searchbarValue,
  searchHandler,
  toggleSidebar,
}) {
  return (
    <header id="app-header-desktop" className="app-header">
      <Searchbar
        value={searchbarValue}
        search={searchHandler}
        setSearchbarValue={setSearchbarValue}
      />
      <LoginHandler />
    </header>
  );
}

DesktopHeader.propTypes = {
  searchbarValue: PropTypes.string.isRequired,
  searchHandler: PropTypes.func.isRequired,
  setSearchbarValue: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default DesktopHeader;
