import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Download from "../Torrents/DownloadItem.jsx";
import { updateLink } from "../../Firebase/lists";

class MovieServers extends Component {
  state = {
    display: true,
    links: false,
    movie_link: "",
    addedmsg: false,
    errormsg: false,
    populardownload: "",
    getData: "",
    server: 1, // Define o servidor 1 como predefinido
  };

  componentDidMount() {
    axios
      .get(
        `https://yts.mx/api/v2/list_movies.json?query_term=${this.props.imdb}&with_images=true&with_cast=true`
      )
      .then((response) => {
        this.setState({
          populardownload: response.data,
        });
        const gg = this.state.populardownload.data.movies;
        gg.forEach((s) => {
          s.torrents.forEach((e) => {
            if (e.quality === "1080p") {
              this.setState({ getData: e.hash });
            }
          });
        });
      })
      .catch((err) => console.log(err));
  }

  cancelModal = () => {
    this.props.hideFunc();
    this.setState({ display: true, server: 1 }); // Reseta para o servidor 1 ao fechar o modal
  };

  openLinks = () => {
    this.setState({ links: true });
  };

  closeLinks = () => {
    this.setState({ links: false });
  };

  submitLink = (event) => {
    event.preventDefault();
    if (this.state.movie_link) {
      updateLink(this.props.id, this.state.movie_link);
      this.setState({ addedmsg: true });
      setTimeout(() => window.location.reload(false), 3000);
    } else {
      this.setState({ errormsg: true });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    if (event.target.type === "text") {
      this.setState({ movie_link: event.target.value });
    }
  };

  // Função para alternar entre servidores
  changeServer = (serverNumber) => {
    this.setState({ server: serverNumber });
  };

  render() {
    const { isOpen, imdb, url } = this.props;
    const { cancelModal, openLinks } = this;

    // Define o link de acordo com o servidor escolhido
    let link = `//player.autoembed.cc/embed/movie/${imdb}`;
    if (this.state.server === 2) {
      link = `//player.autoembed.cc/embed/movie/${imdb}?server=2`;
    } else if (this.state.server === 3) {
      link = `//player.autoembed.cc/embed/movie/${imdb}?server=3`;
    } else if (this.state.server === 4) {
      link = `//vidsrc2.to/embed/movie/${imdb}`;
    }

    const player = (
      <>
        <Download
          handleChange={cancelModal}
          url={url}
          isOpen={isOpen}
          link={link} // Link do player com base no servidor
        />
      </>
    );

    return (
      <>
        <div className={`modal modal--fullscreen ${isOpen ? "is-modal-active" : ""}`}>
          <div className="modal__dialog">
            <div className="modal__content">
              {/* Botão para mudar o servidor */}
              <div className="server-selector">
                <button onClick={() => this.changeServer(1)}>Server 1</button>
                <button onClick={() => this.changeServer(2)}>Server 2</button>
                <button onClick={() => this.changeServer(3)}>Server 3</button>
                <button onClick={() => this.changeServer(4)}>Server 4</button>
              </div>

              <div className="change_link_icon" onClick={openLinks}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                </svg>
              </div>
              <div
                id="open-modal"
                className="modal-window"
                style={{ display: `${this.state.links ? "block" : "none"}` }}
              >
                <div>
                  <a onClick={this.closeLinks} title="Close" className="modal-close">
                    Close
                  </a>
                  <h1>Video Not Found ?</h1>
                  <Link to={`/settings`}>
                    <button className="gdrive-btn">Change Server</button>
                  </Link>
                  <div>
                    <small>Autoembed, multiembed, vidsrc</small>
                  </div>
                </div>
              </div>
              {player}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MovieServers;
