import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Route, Switch } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import DarkModeToggle from "../Darkmode/DarkModeToggle";
import DownloadButton from "../Download/DownloadButton";
import { ToastContainer, toast } from 'react-toastify'; 
import { SignedIn, SignedOut } from "../UserState/UserState";
import UserIcon from "../../assets/user.svg"; // Add this import
import { signOut } from "../../Firebase/UserUtils"; // Import signOut method
import 'react-toastify/dist/ReactToastify.css';
import "./Header.scss";

class MobileHeader extends Component {
  static propTypes = {
    toggleSidebar: PropTypes.func.isRequired,
    searchbarValue: PropTypes.string.isRequired,
    searchHandler: PropTypes.func.isRequired,
    setSearchbarValue: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    searchIsVisible: false,
  };

  searchbarRef = React.createRef();

  showSearch = () => {
    this.setState({ searchIsVisible: true });
  };

  hideSearch = () => {
    this.setState({ searchIsVisible: false });
  };

  handleLogout = () => {
    signOut().then(() => {
      this.props.history.push('/login');
    });
  };

  handleSecureClick = async (user, addPointsToUser, fetchUserPoints) => {
    const result = await addPointsToUser(user.uid, 1);

    if (result === "limit") {
      toast.error("Você já atingiu o limite de 5 cliques hoje.");
    } else {
      await fetchUserPoints(user.uid); 
      this.secureLink.handleClick(); 
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.searchIsVisible) {
      this.searchbarRef.current.inputRef.current.focus();
    }
  }

  render() {
    const {
      toggleSidebar,
      setSearchbarValue,
      searchbarValue,
      searchHandler,
    } = this.props;

    if (this.state.searchIsVisible) {
      return (
        <header id="app-header-mobile" className="app-header">
          <button id="search-hide" onClick={this.hideSearch}>
            {/* Button content */}
          </button>
          <Searchbar
            ref={this.searchbarRef}
            value={searchbarValue}
            search={searchHandler}
            setSearchbarValue={setSearchbarValue}
          />
        </header>
      );
    }


    return (
      <>
        <header id="app-header-mobile" className="app-header">
          <button id="toggle-btn" onClick={toggleSidebar}>
            <svg
              fill="none"
              stroke="currentColor"
              stroke-width="2.2"
              width="24"
              height="24"
              stroke-linecap="round"
              stroke-miterlimit="10"
              viewBox="0 0 24 24"
            >
              <line className="st0" x1="12.2" y1="6.6" x2="18.4" y2="6.6" />
              <line className="st0" x1="6.4" y1="18.4" x2="13" y2="18.4" />
              <line className="st0" x1="6.4" y1="12.5" x2="18.4" y2="12.5" />
            </svg>
          </button>
          <Switch>
            <Route path="/help" render={() => null} />
            <Route path="/login" render={() => null} />
            <Route path="/signup" render={() => null} />
            <Route path="/forgot_password" render={() => null} />
            <Route path="/download" render={() => null} />
            <Route path="/torrent" render={() => null} />
            <Route
              render={() => (
                <button id="header-search-btn" onClick={this.showSearch}>
                  <svg
                    stroke-width="2.2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-activity"
                  >
                    <svg
                      stroke-width="2.2"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </svg>
                </button>
              )}
            />
          </Switch>
          <div className="darkmode-toggle">
            <DownloadButton />
            <DarkModeToggle />
          </div>
          <div className="user-actions">
            <SignedIn>
              {user => (
                <button onClick={this.handleLogout} className="user-button">
                  <UserIcon fill="currentColor" style={{
                    width: "26px",
                    height: "auto", // Missing closing quote corrected
                    marginRight: "10px",
                    borderRadius: "10px",
                    backgroundColor: "var(--icon-bgcolor)",
                    color: "var(--body-color)",
                    transition: "box-shadow 0.15s ease",
                    flexShrink: 0,
                    boxShadow: "var(--default-box-shadow)" }} />
                </button>
              )}
            </SignedIn>
            <SignedOut>
              <button onClick={() => this.props.history.push('/login')} className="user-button">
                <UserIcon fill="currentColor" style={{
                  width: "26px",
                  height: "auto", // Missing closing quote corrected
                  marginRight: "10px",
                  borderRadius: "10px",
                  backgroundColor: "var(--icon-bgcolor)",
                  color: "var(--body-color)",
                  transition: "box-shadow 0.15s ease",
                  flexShrink: 0,
                  boxShadow: "var(--default-box-shadow)" }} />
              </button>
            </SignedOut>
          </div>


        </header>
        <ToastContainer /> {/* Certifique-se de que isso esteja no nível superior */}
      </>
    );
  }
}

export default withRouter(MobileHeader);
